var subtitles__2 = [
	{ "start": "0.9", "end": "6.0", "text": "Wie kann ich den Korridor zum Sprechen bringen?" },

	{ "start": "6.8", "end": "9.6", "text": "Welche Sprachen spricht der Korridor?" },

	{ "start": "10.7", "end": "14.0", "text": "Ein Kommen und Gehen durch die Zeiten." },

	{ "start": "15.8", "end": "24.2", "text": "Der Korridor in der Kunsthalle Osnabrück war früher der Kreuzgang eines Dominikanerklosters." },

	{ "start": "25.3", "end": "33.1", "text": "Im Kreuzgang sind Mönche und Nonnen im Kreis gelaufen und haben gebetet." },

	{ "start": "34.3", "end": "39.9", "text": "Alles ist immer in Bewegung im Kreuzgang und auch im Korridor." },

	{ "start": "40.9", "end": "45.9", "text": "Manila trifft Anne und Monika trifft Christel." }, 

	{ "start": "47.1", "end": "48.4", "text": "Flurfunk" },

	{ "start": "49.4", "end": "51.9", "text": "Flurfunker:innen" },

	{ "start": "53.7", "end": "56.9", "text": "Unter der Decke verlaufen die Kabel." }, 

	{ "start": "58.4", "end": "61.4", "text": "Bewegung. Übertragung." },

	{ "start": "63.1", "end": "66.0", "text": "Es scheint kein Außen zu geben." },

	{ "start": "67.3", "end": "69.0", "text": "verschlungene Pfade" },

	{ "start": "70.2", "end": "74.8", "text": "Techniken des Erscheinens und Verschwindens." },

	{ "start": "76.2", "end": "82.0", "text": "Das war was wir vorhatten, nämlich dieses Haus zu öffnen." },

	{ "start": "84.0", "end": "97.0", "text": "„Steh auf Tochter” sagte die Frau der Vernunft, „wir warten nicht länger, sondern gehen sofort los auf das große Feld des Schreibens." },

	{ "start": "98.0", "end": "107.0", "text": "Dort gibt es nur schöne Dinge wie Früchte und frisches Wasser und dort bauen wir die Stadt der Frauen”!" },

	{ "start": "108.4", "end": "115.3", "text": "Christine de Pizan schrieb um 1400 das Buch der „Stadt der Frauen”" },

	{ "start": "116.7", "end": "120.4", "text": "Eine ideale Stadt von Frauen für Frauen," },

	{ "start": "121.1", "end": "126.2", "text": "gebaut aus vielen Geschichten über Frauen." },

	{ "start": "127.3", "end": "129.0", "text": "Verschlungene Pfade," },

	{ "start": "129.6", "end": "132.8", "text": "ein Kommen und Gehen durch die Zeiten." },

	{ "start": "134.1", "end": "146.4", "text": "Das erste Dominikanerkloster war ein Dominikanerinnenkloster, im Jahr 1206 in Südfrankreich." }, 

	{ "start": "148.3", "end": "155.4", "text": "In Deutschland gab es 70 Jahre später schon 40 Dominikanerinnenklöster." },

	{ "start": "157.2", "end": "164.0", "text": "Christine de Pizan starb in einem Dominikanerinnenkloster." }, 

	{ "start": "165.6", "end": "169.5", "text": "Viele Nonnen haben mystische Texte verfasst" },

	{ "start": "170.5", "end": "171.9", "text": "wie Hildegard von Bingen" },

	{ "start": "172.7", "end": "174.4", "text": "Lingua Ignota" },

	{ "start": "175.0", "end": "177.7", "text": "heißt ihre „Geheimsprache”," },

	{ "start": "179.1", "end": "186.3", "text": "sie hat über 1000 Wörter erfunden und eine eigene „Geheimschrift”:" },

	{ "start": "188.0", "end": "192.6", "text": "Der Buchstabe O sieht aus wie ein Herz" },

	{ "start": "194.5", "end": "199.6", "text": "der Buchstabe B sieht aus wie eine laufende Person" },

	{ "start": "201.3", "end": "203.0", "text": "Im Kreuzgang laufen," },

	{ "start": "203.9", "end": "205.8", "text": "gemeinsam gehen," },

	{ "start": "207.0", "end": "210.8", "text": "Christel sagt: So langsam wie möglich." },

	{ "start": "212.6", "end": "215.9", "text": "Beim Gehen kann sich niemand verstecken." },

	{ "start": "217.6", "end": "234.0", "text": "Von der Straße kommend in den Kreuzgang, dann in den Innenhof und wieder in den Kreuzgang zurück und hinter der Glasscheibe auf die Neue Straße hinaus." },

	{ "start": "235.5", "end": "238.9", "text": "Einfädeln und Ausfädeln" },

	{ "start": "239.7", "end": "241.5", "text": "Kabel verlegen" },

	{ "start": "243.1", "end": "252.8", "text": "Seit das elektronische Licht und das Telefon erfunden wurden hat man Kabel unter der Decke befestigt." }, 

	{ "start": "254.4", "end": "258.1", "text": "Meist unter einer angehängten Decke." },

	{ "start": "260.2", "end": "263.0", "text": "Architekturen für Funken," },

	{ "start": "263.9", "end": "265.8", "text": "schnell wie Licht" },

	{ "start": "267.7", "end": "281.0", "text": "Das Wort Korridor kommt von Kurier und das bedeutet: Jemand läuft sehr schnell um eine wichtige Botschaft zu überbringen." },

	{ "start": "283.4", "end": "288.2", "text": "Wir arbeiten daran, der Flurfunk findet wieder statt." },

	{ "start": "290.1", "end": "297.7", "text": "Wir wollen Diskriminierung verlernen und über Freiheit nachdenken." },

	{ "start": "299.1", "end": "301.7", "text": "Freiheit für wen und wann?" },

	{ "start": "303.1", "end": "305.8", "text": "Alle sollen kommen können." },

	{ "start": "307.2", "end": "310.4", "text": "Dafür habt ihr ja dann auch den Korridor." },

	{ "start": "312.0", "end": "316.1", "text": "Der Korridor als eine feministische Architektur?" },

	{ "start": "318.0", "end": "324.4", "text": "Leitungen, Sprache, Verbindungen, Codes" },

	{ "start": "325.6", "end": "332.5", "text": "Eine Architektur für neue und alte Erzählungen und Erinnerungen." }, 

	{ "start": "334.2", "end": "341.9", "text": "Früher waren in den Kreuzgängen oft Bilder zu sehen, die eine Geschichte erzählten." },

	{ "start": "343.8", "end": "344.9", "text": "Bildprogramme" },

	{ "start": "346.1", "end": "351.3", "text": "Jetzt möchten wir von Christine und Hildegard erzählen dort." }, 

	{ "start": "351.7", "end": "354.1", "text": "Und von Anna und Juliane." },

	{ "start": "355.1", "end": "362.5", "text": "Sie erfinden gerade auch sehr viele neue Wörter für eine neue Kunsthalle." },

	{ "start": "364.0", "end": "372.9", "text": "Und sie führen das Gendern in der Sprache ein, mit einem Doppelpunkt." }, 

	{ "start": "374.3", "end": "380.1", "text": "Vorher hat die Stadt Osnabrück weniger gegendert." },

	{ "start": "381.3", "end": "391.0", "text": "Jetzt wird alles umgeschrieben, neu geschrieben, neu verhandelt, umgewandelt." },

	{ "start": "392.8", "end": "397.7", "text": "Welche Sprachen spricht der Korridor?" },

	{ "start": "398.3", "end": "423.7", "text": "Christine de Pizan hatte zu Beginn des 15. Jahrhunderts, also vor 600 Jahren auch eine eigene Schreibstube, in der sie mit zwei Kopistinnen arbeitete. Sie haben andere Texte kopiert, abgeschrieben." }, 

	{ "start": "425.3", "end": "429.7", "text": "Und neu veröffentlicht, mit gemalten Bildern," }, 

	{ "start": "430.9", "end": "434.9", "text": "auf denen Frauen blaue Kleider tragen," }, 

	{ "start": "436.5", "end": "443.4", "text": "denn im 13. Jahrhundert erwachte das Interesse an Blautönen," },

	{ "start": "444.3", "end": "447.7", "text": "das Blau des Lapislazulis." },

	{ "start": "449.5", "end": "457.2", "text": "Es wird nur für kleine Flächen verwendet in der mittelalterlichen Buchmalerei." },

	{ "start": "458.2", "end": "462.4", "text": "Nur für Flächen die man aufwerten möchte." },

	{ "start": "463.7", "end": "470.8", "text": "Blau bekam die Rolle eines Lichts für den Schein der Figuren," },

	{ "start": "472.1", "end": "475.9", "text": "eine neue Licht-Theologie:" },

	{ "start": "478.0", "end": "481.8", "text": "Ist die Farbe, wenn sie Licht ist, immateriell" },

	{ "start": "483.2", "end": "488.9", "text": "und viel heller als eine Wolke, die die Sonne in sich trägt?" },

	{ "start": "490.2", "end": "495.2", "text": "wie eine Hülle, eine Tünche, eine vanitas" }, 

	{ "start": "496.5", "end": "497.2", "text": "color" }, 

	{ "start": "498.3", "end": "506.8", "text": "celare: verbergen, verschleiern, täuschen" },

	{ "start": "508.8", "end": "509.8", "text": "blaublind" },

	{ "start": "510.7", "end": "514.5", "text": "Maria war nämlich nicht immer blau gekleidet," },

	{ "start": "515.7", "end": "519.3", "text": "die Weiblichkeit der Weisheit" }, 

	{ "start": "520.6", "end": "523.2", "text": "im Deckmantel der Dichtung." }, 

	{ "start": "524.9", "end": "533.1", "text": "Und wie wir noch sehen werden, ist ihre weibliche Form kein bloß grammatisches Geschlecht." }, 

	{ "start": "535.4", "end": "539.0", "text": "Weisheit, Sophia, Sapientia" }, 

	{ "start": "540.2", "end": "541.7", "text": "pigmentarii" }, 

	{ "start": "542.4", "end": "543.3", "text": "vivens" }, 

	{ "start": "544.9", "end": "547.8", "text": "Welche Sprachen spricht der Korridor?" },

	{ "start": "549.2", "end": "553.2", "text": "Der Raum zwischen zwei Funken" },

	{ "start": "554.2", "end": "555.5", "text": "und darin:" },

	{ "start": "555.9", "end": "558.1", "text": "WANDELTREIBERINNEN," }, 

	{ "start": "560.2", "end": "567.0", "text": "sie teilen ein einziges Gehirn, für eine gemeinsame Textproduktion" },

	{ "start": "567.9", "end": "570.3", "text": "mit feministischem Code," },

	{ "start": "571.0", "end": "576.0", "text": "einem queer-feministischen building code." },

	{ "start": "577.5", "end": "583.3", "text": "Hildegard begriff das Medium ihrer Visionen als eine Art Spiegel," },

	{ "start": "583.9", "end": "584.6", "text": "sie sagt:" },

	{ "start": "585.3", "end": "590.9", "text": "Das Licht das ich schaue ist nicht an Raum gebunden." },

	{ "start": "592.2", "end": "595.7", "text": "Re-Visionen, Mikrovisionen" },

	{ "start": "596.7", "end": "599.5", "text": "oder spirituelle Assemblagen," },

	{ "start": "600.5", "end": "601.9", "text": "ein Denken-mit." }, 

	{ "start": "603.8", "end": "606.4", "text": "Jetzt ist oben mehr Luft." },




  ];